import { ProjectStatusType, weightLabel } from '../types/reducers/projects';
import {
    AssetCategoryType,
    AssetTypeType,
    BaseRateType,
    HighCostFactorsType,
    MapcallStatusType,
    OptionType,
    PipeColorLookup,
    PropMaterialType,
    ProjectTypeType,
    RegulatoryStatusType,
    InvestmentProfile,
} from '../types/types';
import { FilterKey } from '../reducers/projects';

export const URLs = {
    makeLoginURL: () => '/auth/login/',
    makeResetPasswordURL: () => '/rest-auth/password/reset/',
    makeConfirmResetPasswordURL: () => '/rest-auth/password/reset/confirm/',
    makeDistrictsURL: () => '/districts/',
    makeWastewaterSystemsURL: () => '/wastewater_systems/',
    makeProjectURL: (id: string | number | null) => (id ? `/projects/${id}/` : ''),
    makeWaterPipesImpactScoresURL: (queryString: string) => `/water-pipes/impact_scores/${queryString}`,
    makeSelectWaterPipesURL: () => '/water-pipes/select/',
    makeWaterPipesDetailsURL: () => '/water-pipes/details/',
    makeWaterPipesURL: (queryString?: string) => `/water-pipes/${queryString ?? ''}`,
    makeSelectWastewaterPipesURL: () => '/wastewater-pipes/select/',
    makeWastewaterPipesDetailsURL: () => '/wastewater-pipes/details/',
    makeWastewaterPipesURL: (queryString?: string) => `/wastewater-pipes/${queryString ?? ''}`,
    makeLogoutURL: () => '/auth/logout/',
    makePaginatorURL: (queryString: string) => `/projects/${queryString}&paginator_only=true`,
    makeProjectsURL: (queryString: string) => `/projects/${queryString}`,
    makeProjectsCsvURL: (queryString: string) => `/projects/${queryString}&csv=true`,
    makeCreatedByURL: () => '/projects/createdby',
    makeProjectLimitURL: () => '/projects/limits',
    makeProjectFoundationalFilingPeriodURL: () => '/projects/foundational_filing_periods',
    makeProjectSubmitURL: (id: string) => `/projects/${id}/submit/`,
    makeProjectEditURL: (id: string | null) => (id ? `/projects/${id}/edit` : ''),
    makeProjectApprovalURL: (id: string | number | null) => (id ? `/projects/${id}/approve/` : ''),
    makeProjectDenialURL: (id: string | number | null) => (id ? `/projects/${id}/deny/` : ''),
    makeProjectCompleteURL: (id: string | number | null) => (id ? `/projects/${id}/complete/` : ''),
    makeProjectRevertURL: (id: string | number | null) => (id ? `/projects/${id}/revert/` : ''),
    makeDashboardHeaderURL: (queryString: string) => `/dashboard/header/${queryString}`,
    makeDashboardApprovalRatesURL: (queryString: string) => `/dashboard/approval-rates/${queryString}`,
    makeDashboardTimeGapsURL: (queryString: string) => `/dashboard/time-gaps/${queryString}`,
    makeDashboardTimeURL: (queryString: string) => `/dashboard/time/${queryString}`,
    makeDashboardProjectCostsURL: (queryString: string) => `/dashboard/project-costs/${queryString}`,
    makeDashboardProjectCostsAndScoresURL: (queryString: string) =>
        `/dashboard/project-costs-and-scores/${queryString}`,
    makeDashboardProjectCostsPerFootURL: (queryString: string) => `/dashboard/project-costs-per-foot/${queryString}`,
    makeDashboardTopDistrictsURL: (queryString: string) => `/dashboard/top-districts/${queryString}`,
    makeDashboardTopCreatorsURL: (queryString: string) => `/dashboard/top-creators/${queryString}`,
    makeDashboardScoresURL: (queryString: string) => `/dashboard/scores/${queryString}`,
    makeDashboardScoringByConfigurationURL: (queryString: string) =>
        `/dashboard/scoring-by-configuration/${queryString}`,
    getAllGISLayersURL: (state: string) => `/layers/?state=${state}`,
    makeTownsFilterURL: () => `/projects/towns/`,
    makeTownsURL: (queryString: string) => `/towns/${queryString}`,
    makeProjectResetUrl: (id: string) => `/projects/${id}/reset/`,
    makeProjectDeletionURL: (id: string | number | null) => `/projects/${id}/delete/`,
    makeProjectCountURL: (queryString: string) => `/projects/?${queryString}&count_only=true`,
    getSearchSuggestions: (queryString: string) => `projects/search_autocomplete/${queryString}`,
    makeInstructionViewsURL: () => 'auth/instruction_view/',
};

export const Paths = {
    home: '/',
    emailLogin: '/login/email',
    oktaLogin: '/login/sso',
    confirmEmail: '/email/confirm',
    resetPassword: '/reset',
    confirmPasswordReset: '/user/resetpassword/:uid',
    projects: '/projects',
    newProject: '/projects/new',
    editProject: '/projects/edit',
    project: '/projects/:universal_id',
    projectEdit: '/projects/:universal_id/edit',
    explore: '/projects/explore',
    djangoAdmin: '/admin/',
};

export const mapWidth = 500;
export const mapHeight = 500;
export const initialViewport = {
    width: 'auto',
    height: '100%',
    longitude: -74.4057,
    latitude: 40.0583,
    zoom: 7,
    pitch: 0,
    bearing: 0,
    maxZoom: 18,
    minZoom: 0,
    padding: 10,
};

export type weightCategoryLabel =
    | 'Likelihood of Failure'
    | 'Consequence of Failure'
    | 'System Flows and Pressures'
    | 'Water Quality'
    | 'Lead Services';

export const weightLabels: Record<weightLabel, weightCategoryLabel> = {
    sr_weight: 'Consequence of Failure',
    srs_weight: 'Likelihood of Failure',
    sfp_weight: 'System Flows and Pressures',
    wq_weight: 'Water Quality',
    ls_weight: 'Lead Services',
};
export const weightCategories: Record<weightCategoryLabel, weightLabel> = {
    'Likelihood of Failure': 'srs_weight',
    'Consequence of Failure': 'sr_weight',
    'System Flows and Pressures': 'sfp_weight',
    'Water Quality': 'wq_weight',
    'Lead Services': 'ls_weight',
};

export const limitExceptionLabels = {
    ...weightLabels,
    total_impact_score: 'Total Impact Score',
    rehab_pipe_pct: 'Rehab Pipe Percentage',
};

export const justificationsAndDescriptions: Record<string, string> = {
    Conservation: 'Non-Revenue water, CPS, or District Improvement Plan, etc.',
    'Relocation / Opportunity': 'Required due to conflicts, moratorium, cost-sharing, etc.',
    'Likelihood of Failure': 'Removing mains with high failure likelihood ',
    'Crossing Risk Reduction': 'Specific critical crossings (bridge, rail, etc.)',
    'Consequence of Failure': 'Potential high-impact from main breaks, critical customers, etc.',
    'Sustained Economic Growth': 'CPS or District Improvement, Infill Growth, Feeder Mains',
    'System Flows and Pressures': 'Inadequate fire flow, undersized main, CPS or DIP',
    'Water Quality': 'Reduce water quality complaints, improve water quality',
    'Lead Services': 'Removing mains with high lead service counts',
};

export const waterPipeTableHeaderFields: { [key: string]: string } = {
    // Format is 'label/unit'
    mainlength: 'Length/ft',
    diameter: 'Diameter/in',
    install_date: 'Date Installed/yyyy-mm-dd',
    material: 'Material',
    lof: 'LOF',
    cof: 'COF',
    fflow: 'FFLOW',
    wq: 'WQ',
    lead: 'LEAD',
    breakfreq: 'Break Frq.',
    fflow_val: 'Fire Flow/gpm',
    is_rehab: 'Rehab Eligible',
};

export const wastewaterPipeTableHeaderFields: { [key: string]: string } = {
    // Format is 'label/unit'
    mainlength: 'Length/ft',
    diameter: 'Diameter/in',
    install_date: 'Date Installed/yyyy-mm-dd',
    material: 'Material',
    breakfreq: 'Break Frq.',
    slope: 'Slope/%',
    upstream_invert: 'Upstream Invert',
    downstream_invert: 'Downstream Invert',
    depth: 'Depth/ft',
};

export const defaultProjectWeights = {
    sr_weight: 0,
    srs_weight: 0,
    sfp_weight: 0,
    wq_weight: 0,
    ls_weight: 0,
};

export const LIGHT_GRAY = '#D9D9D9';
export const DARK_GRAY = '#3f3f46';

export const impactScoreFilterOptions = [
    { value: '0.0-0.9', label: '0.0 - 0.9' },
    { value: '1.0-1.9', label: '1.0 - 1.9' },
    { value: '2.0-2.9', label: '2.0 - 2.9' },
    { value: '3.0-3.9', label: '3.0 - 3.9' },
    { value: '4.0-5.0', label: '4.0 - 5.0' },
];

export const MARKER_SHADOW = 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))';

export const PROJECT_STATUS_DESCRIPTION: Record<ProjectStatusType, string> = {
    DRAFT: 'Draft',
    PENDING: 'Pending Approval',
    AUTO_APPROVED: 'System Approved',
    APPROVED: 'Admin Approved',
    DENIED: 'Rejected',
    COMPLETE: 'Complete',
};

export const PROJECT_STATUS: Record<ProjectStatusType, string> = {
    DRAFT: 'DRAFT',
    PENDING: 'PENDING',
    AUTO_APPROVED: 'AUTO_APPROVED',
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    COMPLETE: 'COMPLETE',
};

export const PROJECT_STATUSES = Object.keys(PROJECT_STATUS).map((s) => s as ProjectStatusType);

export const FILTER_MODES = { closed: 'CLOSED', common: 'COMMON', all: 'ALL' };

export const SELECT_ALL_OPTION: OptionType = { value: 'all', label: 'Select All', disabled: false };

// organized in project workflow order
export const mapcallStatuses: Array<MapcallStatusType> = [
    'Proposed',
    'Submitted',
    'AP Approved',
    'AP Endorsed',
    'Manager Endorsed',
    'Complete',
    'Canceled',
];
export const regulatoryStatuses: Array<RegulatoryStatusType> = [
    '2018 GAP',
    'BPU Approved',
    'BPU Submitted',
    'BPU Substituted In',
    'BPU Substituted Out',
    'Complete',
];

export const propDiameters: number[] = [
    0.75, 1, 2, 2.25, 2.5, 3, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 30, 36, 42, 48, 54, 60, 72,
];
export const assetCategories: Array<AssetCategoryType> = ['Wastewater', 'Water'];
export const assetTypes: Array<AssetTypeType> = [
    'Equipment',
    'Facility',
    'Hydrant',
    'Main',
    'Main Crossing',
    'Service',
    'Sewer Lateral',
    'Sewer Main',
    'Sewer Opening',
    'Storm/Catch',
    'Valve',
];
export const highCostFactors: Array<HighCostFactorsType> = [
    'Asbestos Pipe Removal',
    'Bridge Replacement',
    'Excessive Depth',
    'Extensive Restoration',
    'Ground Water',
    'Jack and Bore',
    'Material Volitility',
    'None',
    'Rock',
    'Stream Crossings',
    'Traffic',
    'Unknown',
    'Utility Interference',
];
export const propMaterials: Array<PropMaterialType> = [
    'Asbestos Cement',
    'Cast Iron',
    'Concrete',
    'Ductile Iron',
    'FRP',
    'Galvanized Steel',
    'HDPE',
    'Lead',
    'Other',
    'Other Plastic',
    'PCCP',
    'PVC',
    'Steel',
    'Vitrified Clay',
    'Wood',
];

// The values in `propMaterials` are different than what we see in the
// main data. This attempts to map from propMaterial value to corresponding
// value in the pipe data
export const pipeMaterialMapping = {
    'Asbestos Cement': 'AC',
    'Cast Iron': 'CI',
    Concrete: 'CEM',
    'Ductile Iron': 'DI',
    PVC: 'PVC',
    Steel: 'ST',
    'Galvanized Steel': 'GALV',
    HDPE: 'HDPE',
    Lead: 'LD',
    Other: 'OTH',
    'Other Plastic': 'PL-UNK',
    PCCP: 'CEM',
    // We did not find the values below in the
    // NJ pipe data so we map to the same string
    FRP: 'FRP',
    'Vitrified Clay': 'Vitrified Clay',
    Wood: 'Wood',
};

export const projectTypes: Array<ProjectTypeType> = ['New', 'Rehab', 'Replace'];
export const baseRateOptions: Array<BaseRateType> = ['Yes', 'No', 'Unsure'];

interface PresetRange {
    label: string;
    min: number;
    max: number;
}
export const presetEstimateOptions: Array<PresetRange> = [
    { label: '$0 - 25,000', min: 0, max: 25000 },
    { label: '$25,000 - 60,000', min: 25000, max: 60000 },
    { label: '$60,000 - 1M', min: 60000, max: 1000000 },
    { label: '$1M - 2M', min: 1000000, max: 2000000 },
    { label: '$2M - 3M', min: 2000000, max: 3000000 },
    { label: '$3M - 6M', min: 3000000, max: 6000000 },
    { label: '$6M - 12M', min: 6000000, max: 12000000 },
    { label: '> 12M', min: 12000000, max: 50000000 },
];

export const presetEstDurationOptions: Array<PresetRange> = [
    { label: '1-6 Days', min: 1, max: 7 },
    { label: '1-4 Weeks', min: 7, max: 30 },
    { label: '1-12 Months', min: 30, max: 365 },
    { label: '1+ Years', min: 365, max: 365 * 3 },
];

export const widgetGraphHeight = 300;

/*
    Pipe/project coloring
*/
type colorObjType = { dark: string; light: string };

const WHITE = { dark: '#71717A', light: '#F4F4F5' };
const BLUE = { dark: '#56BCF2', light: '#D0ECFB' };
const GREEN = { dark: '#6B9C40', light: '#E5F0DB' };
const YELLOW = { dark: '#F7CE46', light: '#FDF2CE' };
const ORANGE = { dark: '#FD974D', light: '#FEE2CD' };
const RED = { dark: '#EA3323', light: '#FBD4D0' };

export const setFixed = (n: number | string): string => {
    if (typeof n === 'string') {
        return parseFloat(n).toFixed(1);
    }
    return n.toFixed(1);
};

// Maps score categories for the cluster map to colors
export const SCORE_CATEGORY_COLORS: Record<string, string> = {
    '0': WHITE.dark,
    '1': BLUE.dark,
    '2': GREEN.dark,
    '3': YELLOW.dark,
    '4': ORANGE.dark,
    '5': RED.dark,
};

export const pipeColors: PipeColorLookup = [
    { baseColor: [101, 147, 245], mutedColor: [179, 202, 250] }, // Blue
    { baseColor: [0, 128, 0], mutedColor: [144, 224, 144] }, // Green
    { baseColor: [245, 208, 0], mutedColor: [220, 209, 185] }, // Yellow
    { baseColor: [255, 140, 0], mutedColor: [255, 198, 133] }, // Orange
    { baseColor: [220, 20, 60], mutedColor: [248, 180, 194] }, // Red
];
export const getProjectScoreColor = (projectScore: number | null | string) => {
    if (!projectScore) {
        return WHITE;
    }
    const fixedScore = parseFloat(setFixed(projectScore));
    if (fixedScore >= 1 && fixedScore < 2) {
        return BLUE;
    }
    if (fixedScore >= 2 && fixedScore < 3) {
        return GREEN;
    }
    if (fixedScore >= 3 && fixedScore < 4) {
        return YELLOW;
    }
    if (fixedScore >= 4 && fixedScore < 5) {
        return ORANGE;
    }
    if (fixedScore === 5) {
        return RED;
    }

    return WHITE;
};

// There is a different color scale for pipes than for projects. For example,
// blue is associated with project scores >= and < 1 but with pipe scores == 1.
export const getPipeScoreColor = (pipeScore: number): colorObjType => {
    return getProjectScoreColor(pipeScore - 1);
};

export const getBackgroundColor = (num: number): string | undefined => {
    return getProjectScoreColor(num)?.dark;
};

export const SCORE_CATEGORIES = Object.keys(SCORE_CATEGORY_COLORS).map((k) => parseInt(k));

export const DEFAULT_FILTERS: Array<{ filterKey: FilterKey; selections: string[] }> = [
    {
        filterKey: 'impactScoreFilter',
        selections: ['0.0-0.9', '1.0-1.9', '2.0-2.9', '3.0-3.9', '4.0-5.0'],
    },
];

export const esriDashedLinesStyles = [
    'esriSLSDash',
    'esriSLSDot',
    'esriSLSLongDash',
    'esriSLSShortDash',
    'esriSLSShortDot',
    'esriSLSDashDot',
    'esriSLSDashDotDot',
    'esriSLSLongDashDot',
    'esriSLSShortDashDot',
    'esriSLSShortDashDotDot',
];

export const FEEDBACK_FORM_URL =
    'https://docs.google.com/forms/d/e/1FAIpQLScDLHbNC7dq4htMyzwK6AxnHQ3wGYPKKHVlAz3bzykp2_3anw/viewform';

// This will eventually need to be configured to be a different email for each state
export const ADMIN_EMAIL = 'Christopher.Kahn@amwater.com';

// Time in ms to wait after map viewstate changes in order to update the data
export const MAP_VIEWSTATE_DEBOUNCE = 250;

// Chunk size to use when downloading paginated records for csv download
export const PROJECT_DOWNLOAD_PAGE_SIZE = 100;

export const INVESTMENT_PROFILES: InvestmentProfile[] = [
    {
        title: 'Likelihood of Failure',
        name: 'lof',
        description: 'Risk model identifying future failure. Most appropriate for <16" projects.',
        weights: {
            sr_weight: 0,
            srs_weight: 1,
            sfp_weight: 0,
            wq_weight: 0,
            ls_weight: 0,
        },
    },
    {
        title: 'Lead weighted Likelihood of Failure',
        name: 'l_lof',
        description: 'The likelihood of failure model weighted by the presence of lead & galvanized service lines.',
        weights: {
            sr_weight: 0,
            srs_weight: 0.75,
            sfp_weight: 0,
            wq_weight: 0,
            ls_weight: 0.25,
        },
    },
    {
        title: 'Fire Flow / Pressure',
        name: 'fflow',
        description: 'Risk model for identifying pressure and fire flow challenges.',
        weights: {
            sr_weight: 0,
            srs_weight: 0,
            sfp_weight: 1,
            wq_weight: 0,
            ls_weight: 0,
        },
    },
    {
        title: 'Blended',
        name: 'blended',
        description:
            'Comprehensive risk model covering multiple business drivers. Most appropriate for large diameter projects.',
        weights: {
            sr_weight: 0.5,
            srs_weight: 0.5,
            sfp_weight: 0,
            wq_weight: 0,
            ls_weight: 0,
        },
    },
];

export const initialProfileScoreState = { l_lof: null, fflow: null, lof: null, blended: null };

export const instructionViewLimit = 3;

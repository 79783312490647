// Place to store reusable styling configurations

import theme from '../theme';

const { blue, gray } = theme.colors.brand;

export const fontMediumBold = {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0em',
};

export const fontMediumRegular = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
    letterSpacing: '0em',
};

export const fontSmallRegular = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0em',
};

export const fontXsRegular = {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0em',
};

export const baseActionButton = {
    fontSize: 'small',
    color: 'white',
    borderRadius: '4px',
};

export const actionButton = {
    fontSize: 'small',
    height: '32px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '4px',
};

export const submitButton = {
    fontSize: 'small',
    height: '40px',
    color: 'white',
    borderRadius: '4px',
};

export const statusStyle = (size?: string) => ({
    borderRadius: '4px',
    borderWidth: '1px',
    fontSize: '14px',
    fontWeight: '400',
    padding: size == 'lg' ? '6px' : '4px',
    width: 'fit-content',
    height: size == 'lg' ? '38px' : 'fit-content',
});

export const statusDone = {
    borderColor: '#A3BD9E',
    background: '#53AE42',
    color: 'white',
};

export const statusApproved = {
    borderColor: '#A3BD9E',
    background: 'rgba(83, 174, 66, 0.33)',
    color: 'black',
};

export const statusDenied = {
    borderColor: '#763000',
    background: '#CD5300',
    color: 'white',
};

export const statusNeutral = {
    borderColor: '#D4D4D8',
    background: '#F4F4F5',
    color: '#3F3F46',
};

export const projectListFooterButton = {
    fontSize: '12px',
    border: '1px',
    borderRadius: '3px',
    px: '8px',
    py: '6px',
    shadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.1)',
    height: '100%',
    fontWeight: 'bold',
    variant: 'solid',
};

export const paginationButton = {
    ...projectListFooterButton,
    background: 'white',
};

export const csvDownloadButton = {
    ...projectListFooterButton,
    background: blue,
    color: 'white',
    borderColor: 'white',
};

export const tableTooltip = {
    color: 'black',
    backgroundColor: 'white',
    border: 'border: "1px solid #D4D4D8"',
    borderRadius: '2px',
    boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    maxWidth: '400px',
};

export const creationFlowTooltipStyle = {
    color: 'black',
    backgroundColor: gray,
    borderRadius: '4px',
    boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    maxWidth: '200px',
    arrowSize: 10,
    hasArrow: true,
};

export const projectValidationStyle = {
    fontSize: '14px',
    height: '38px',
};

export const MAP_CONTROL_WIDTH = '348px';
export const SIDEBAR_WIDTH = '325px';
export const TABLE_HEIGHT = '200px';

export const GISLAYERS_LEGEND_ICON_SIZE = '15px';

export const SIDEBAR_ACCORDION_BUTTON_HEIGHT = '40px';

export const menuItemStyles = {
    _hover: { bg: 'white' },
    padding: '3px 12px 3px 12px',
};

export const scrollBarStyles = {
    sx: {
        // Target the scrollbar
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#C0C0C0',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            marginRight: '4px',
        },
    },
};

export const mutedTextColor = '#C0C0C0';
export const porcelain = '#E5EAEE';

import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { MapView, ViewStateChangeParameters } from '@deck.gl/core';
import { pick } from 'lodash';

import * as api from '../api';
import { RootState } from '../store';
import { getErrorMessage } from '../util/utils';
import { ThunkType } from '../types/types';
import { createClearOnLogout } from './auth';
import { initialViewport, mapHeight, mapWidth } from '../util/constants';
import { GISLayerType, GISLayerDataByName, MapCoordinates } from '../types/reducers/mapData';
import createViewPortFromBounds from '../util/createViewPortFromBounds';
import { AxiosError } from 'axios';
import { ClusterMapState } from '../types/reducers/clusterMap';

const initialState: ClusterMapState = {
    viewport: initialViewport,
    gisLayers: {},
    extent: [-75, 39.5, -74, 40.5], // extent for all districts in NJ
    loading: false,
    error: null,
    pin: undefined,
};

const clearOnLogout = createClearOnLogout<ClusterMapState>(initialState);
export const clusterMapSlice = createSlice({
    name: 'clusterMap',
    initialState,
    reducers: {
        setViewport: (state: ClusterMapState, { payload }) => {
            state.viewport = { ...state.viewport, ...payload };
        },
        setClusterMapExtent: (state: ClusterMapState, { payload }) => {
            state.extent = payload;
        },
        clearViewport: (state: ClusterMapState) => {
            state.extent = initialState.extent;
            state.viewport = initialState.viewport;
        },
        startFetchGISLayers: (state: ClusterMapState) => {
            state.loading = true;
            state.error = null;
        },
        completeFetchGISLayers: (state: ClusterMapState, { payload }) => {
            state.gisLayers = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchGISLayers: (state: ClusterMapState, { payload }) => {
            state.gisLayers = {};
            state.loading = false;
            state.error = payload;
        },
        startSetMapSearchPoint: (state: ClusterMapState) => {
            state.loading = true;
            state.error = '';
        },
        completeSetMapSearchPoint: (state: ClusterMapState, { payload }: PayloadAction<MapCoordinates>) => {
            const { latitude, longitude, extent } = payload;
            state.viewport.latitude = latitude;
            state.viewport.longitude = longitude;
            state.extent = extent;
            state.pin = [longitude, latitude];
            state.loading = false;
        },
        failSetMapSearchPoint: (state: ClusterMapState, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        clearClusterMapSearchPoint: (state: ClusterMapState) => {
            state.pin = undefined;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    setViewport,
    setClusterMapExtent,
    clearViewport,
    startFetchGISLayers,
    completeFetchGISLayers,
    failFetchGISLayers,
    startSetMapSearchPoint,
    completeSetMapSearchPoint,
    failSetMapSearchPoint,
    clearClusterMapSearchPoint,
} = clusterMapSlice.actions;

export const setClusterMapViewport =
    (extent: [number, number, number, number]): ThunkType =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const viewport = createViewPortFromBounds({
            bounds: extent,
            width: mapWidth,
            height: mapHeight,
        });

        dispatch(setViewport(viewport));
    };

export const setClusterMapViewState =
    (e: ViewStateChangeParameters): ThunkType =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const view = new MapView(e.viewState);
        const viewport = view.makeViewport({
            width: view.props.width as number,
            height: view.props.height as number,
            viewState: e.viewState,
        });
        if (viewport) {
            // round off the precision of the bbox coords
            // xmin and ymin get rounded down, xmax and ymax get rounded up
            // this has two benefits:
            //   1. it expands the extent of the fetched data slightly beyond the viewport
            //   2. it lowers the threshold for needing to refetch the data (i.e.
            //      if the new data extent is within the old one it should not refetch)

            // this `roundTo` value defines the precision to round the extent coords to
            // e.g. `1 / 1000` will round them to three decimal places
            const roundTo = 1 / 1000;
            const extent = viewport?.getBounds();
            const xmin = Math.floor(extent[0] / roundTo) * roundTo;
            const ymin = Math.floor(extent[1] / roundTo) * roundTo;
            const xmax = Math.ceil(extent[2] / roundTo) * roundTo;
            const ymax = Math.ceil(extent[3] / roundTo) * roundTo;

            dispatch(setClusterMapExtent([xmin, ymin, xmax, ymax]));
            dispatch(
                setViewport(
                    pick(viewport, [
                        'width',
                        'height',
                        'latitude',
                        'longitude',
                        'zoom',
                        'pitch',
                        'bearing',
                        'padding',
                        'minZoom',
                        'maxZoom',
                    ]),
                ),
            );
        }
    };

export const fetchAllGISLayers = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startFetchGISLayers());
    try {
        const gisLayersData = await api.getGISLayers('nj');
        // If link for ESRI feature server, update to query for all layer id's in feature
        // Will use id's to create individual geojson data links under one layer name
        const gisFeatureLayerPromises = gisLayersData.layers.map(async (layer) => {
            const data =
                layer.url.endsWith('/FeatureServer') &&
                (await fetch(`${layer.url}/layers/query?f=json`).then((res) => res.json()));
            return { ...layer, featureServiceData: data ?? null };
        });
        const layersAndFeatureLayersData = await Promise.all(gisFeatureLayerPromises);
        const allLayersByName = layersAndFeatureLayersData.reduce(
            (layersByName: GISLayerDataByName, layer: GISLayerType) => {
                const { layers } = layer.featureServiceData;
                layersByName[layer.name] = !layer.featureServiceData
                    ? layer.url
                    : layers.map((l: { name: string; id: number }, index: number) => {
                          const { drawingInfo, minScale } = layers[index];
                          return {
                              id: l.name,
                              data: `${layer.url}/${l.id}/query?f=geojson&where=1%3D1&maxRecordCountFactor=1000&resultType=standard&outFields=*`,
                              minScale: minScale,
                              drawingInfo: drawingInfo,
                              parent: layer.name,
                          };
                      });
                return layersByName;
            },
            {},
        );
        dispatch(completeFetchGISLayers(allLayersByName));
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Error fetching gis layers.');

        dispatch(failFetchGISLayers(message));
    }
};

export const setClusterMapSearchPoint =
    (payload: { value: string; label: string }): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startSetMapSearchPoint());
        try {
            const { value, label } = payload;
            const searchPoint = await api.getGeocodeResponse(label, value);
            const { extent } = searchPoint;

            dispatch(completeSetMapSearchPoint(searchPoint));
            dispatch(setClusterMapViewport(extent));
        } catch (e: unknown) {
            const message: string =
                e instanceof AxiosError ? e.response?.data?.detail : 'Error setting map search point';

            dispatch(failSetMapSearchPoint(message));
        }
    };

export const selectClusterMapViewport = (state: RootState) => ({ ...state.clusterMap.viewport });
export const selectClusterMapExtent = (state: RootState) => state.clusterMap.extent;
export const selectClusterMapGISLayers = (state: RootState) => ({ ...state.clusterMap.gisLayers });
export const selectClusterMapPin = (state: RootState) => state.clusterMap.pin;
export default clusterMapSlice.reducer;
